.css-sprite-ELLIPTICAL-TOP
{
background:url('../ColorImages/css-sprite-combined1.png') -10px -0px;
width:150px;height:150px;
display:inline-block;
}
.css-sprite-EYEBROW-TOP
{
background:url('../ColorImages/css-sprite-combined1.png') -170px -0px;
width:150px;height:150px;
display:inline-block;
}
.css-sprite-MUSTACHE-TOP
{
background:url('../ColorImages/css-sprite-combined1.png') -330px -0px;
width:150px;height:150px;
display:inline-block;
}
.css-sprite-ROUND-TOP
{
background:url('../ColorImages/css-sprite-combined1.png') -490px -0px;
width:150px;height:150px;
display:inline-block;
}
.css-sprite-SQUARE-TOP
{
background:url('../ColorImages/css-sprite-combined1.png') -650px -0px;
width:150px;height:150px;
display:inline-block;
}