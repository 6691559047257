.css-sprite-with-sidelight-and-transom
{
background:url('../ColorImages/css-sprite-combined.3.png') 0px -0px;
width:140px;height:200px;
display:inline-block;
}

.css-sprite-with-sidelights
{
background:url('../ColorImages/css-sprite-combined.3.png') -140px -0px;
width:140px;height:200px;
display:inline-block;
}

.css-sprite-with-simulated-divided-light
{
background:url('../ColorImages/css-sprite-combined.3.png') -280px -0px;
width:140px;height:200px;
display:inline-block;
}

.css-sprite-with-transom
{
background:url('../ColorImages/css-sprite-combined.3.png') -420px -0px;
width:140px;height:200px;
display:inline-block;
}

.css-sprite-with-true-divide-lights
{
background:url('../ColorImages/css-sprite-combined.3.png') -560px -0px;
width:140px;height:200px;
display:inline-block;
}

.css-sprite-with-operable-glass
{
background:url('../ColorImages/css-sprite-combined.3.png') -700px -0px;
width:140px;height:200px;
display:inline-block;
}

