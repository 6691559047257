
 
.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
/* Container for other content */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

/* Style for button */
button {
  padding: 15px 30px;
  background-color: #4CAF50;
  color: white;
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

/* Overlay that covers the entire screen and ensures the popup appears on top */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* ensures it appears above other content */
}

/* Popup styling */
.popup {
  background-color: #28a745;
  color: white;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  animation: popupAnimation 1s ease-in-out;
  max-width: 400px;  /* Ensure the popup doesn't stretch too wide */
  width: 90%;  /* Make it responsive */
  text-align: center;  /* Ensure text inside the popup is centered */
}

/* Center the content inside the popup */
.popup-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack the emoji and text vertically */
}

.emoji {
  font-size: 30px;
  margin: 0 10px;
}

h2 {
  font-size: 20px;
  margin: 10px 0;
}

/* Popup animation */
@keyframes popupAnimation {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
