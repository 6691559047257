

 .css-sprite-double-door-sidelights
 {
 background:url('../ColorImages/css-sprite-combineddoubledoor.png') 0px -0px;
 width:150px;height:150px;
 display:inline-block;
 }
 
 .css-sprite-double-door-true-divide-lights
 {
 background:url('../ColorImages/css-sprite-combineddoubledoor.png') -150px -0px;
 width:150px;height:150px;
 display:inline-block;
 }
 
 .css-sprite-double-door-transom
 {
 background:url('../ColorImages/css-sprite-combineddoubledoor.png') -300px -0px;
 width:150px;height:150px;
 display:inline-block;
 }
 
 .css-sprite-double-door-simulated-divided-light
 {
 background:url('../ColorImages/css-sprite-combineddoubledoor.png') -450px -0px;
 width:150px;height:150px;
 display:inline-block;
 }
 
 .css-sprite-double-door-sidelight-and-transom
 {
 background:url('../ColorImages/css-sprite-combineddoubledoor.png') -600px -0px;
 width:150px;height:150px;
 display:inline-block;
 }
 
 .css-sprite-double-door-sidelights1
 {
 background:url('../ColorImages/css-sprite-combineddoubledoor.png') -750px -0px;
 width:150px;height:150px;
 display:inline-block;
 }
 
 