.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    height: 700px;
   }
  
  .color-box {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(13% - 6px);
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    /* font-weight: bold; */
    cursor: pointer;
    border-radius: 53%;
    transition: transform 0.2s;
    width: 30%;
}
  
  .color-box:hover {
    transform: scale(1.1);
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .color-box {
      flex: 0 1 calc(50% - 10px); /* 2 boxes per row on smaller screens */
    }

  .color-box {
    height: 115px;
  }

  }
  
  @media (max-width: 480px) {
    .color-box {
      flex: 0 1 100%; /* 1 box per row on mobile */
    }
  }
  