
.css-sprite-Rain
{
background:url('../ColorImages/glassimg2.png') 0px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-reeded
{
background:url('../ColorImages/glassimg2.png') -150px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-satin-etch
{
background:url('../ColorImages/glassimg2.png') -300px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-solar-cool-bronze
{
background:url('../ColorImages/glassimg2.png') -450px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-solar-cool-gray
{
background:url('../ColorImages/glassimg2.png') -600px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-atlantic
{
background:url('../ColorImages/glassimg2.png') -750px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-bronze
{
background:url('../ColorImages/glassimg2.png') -900px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-clear-shgc-78
{
background:url('../ColorImages/glassimg2.png') -1050px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-clear-glass
{
background:url('../ColorImages/glassimg2.png') -1200px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-delta-clear
{
background:url('../ColorImages/glassimg2.png') -1350px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-flemish
{
background:url('../ColorImages/glassimg2.png') -1500px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-glacier
{
background:url('../ColorImages/glassimg2.png') -1650px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-glue-chip
{
background:url('../ColorImages/glassimg2.png') -1800px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-grey
{
background:url('../ColorImages/glassimg2.png') -1950px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-obscure
{
background:url('../ColorImages/glassimg2.png') -2100px -0px;
width:150px;height:150px;
display:inline-block;
}
