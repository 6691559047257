.config-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #F7F7F7;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.css-1gzgum9-MuiTypography-root ,.css-1amq2nz-MuiTypography-root  {
  border-radius: 6px;

}
h2 {
  text-align: center;
  color: #333;
}

.config-section {
  margin-bottom: 20px;
}

.config-section h3 {
  background-color: #E7E7E7;
  border-radius: 5px;
  padding: 1px 6px;
  color: #333;
}

.config-section p {
  margin: 0;
  font-size: 16px;
  color: #666;
  padding: 1px 5px;
}

.config-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.inquiry-btn,
.print-btn {
  width: 48%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.inquiry-btn {
  background-color: #FF9800;
  color: #fff;
}

.print-btn {
  background-color: #757575;
  color: #fff;
}

@media (max-width: 600px) {
  .config-container {
    padding: 5px;
  }
  .config-buttons {
    flex-direction: column;
  }
  .inquiry-btn,
  .print-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}
