/* Body Styling */
.full1 {
    background-color: #5f1c1d;  /* Background color */
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
     position: "relative"
  }
  
  /* Container for UserTable */
  .user-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  /* Styling for the Login Form */
  .login-form-container {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 60%;
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 12%;
  }
  
  .login-form-container h2 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form .input-group {
    margin-bottom: 15px;
  }
  
  .login-form .input-group label {
    font-size: 14px;
    color: #555;
    display: block;
    margin-bottom: 5px;
  }
  
  .login-form .input-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #5f1c1d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #45181a;
  }
  
  /* Styling for the Data Table */
  .data-container {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 80%;
    /* max-width: 900px; */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 30px 1px;
  }
  
  .data-container h2 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .user-data-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-data-table th, .user-data-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .user-data-table th {
    background-color: #5f1c1d;
    color: white;
  }
  
  .user-data-table tr:nth-child(even) {
    background-color: #f4f4f4;
  }
  
  .user-data-table tr:hover {
    background-color: #ddd;
  }
  

  /* App.css */

/* Main App Container */
.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}

/* Button for triggering popup */
.app-container__button {
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.app-container__button:hover {
  /* background-color: #45a049; */
  transform: scale(1.05);
}

/* Overlay that darkens the background */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Slightly darker overlay for focus */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease-out;
}

/* Simplified Popup Container */
.delete-popup {
  background-color: #fff; /* Simple white background */
  padding: 30px;
  border-radius: 12px; /* Slightly rounded corners */
  width: 500px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.6s ease-out;
  transform: scale(0.95);
}

/* Popup Title */
.delete-popup__title {
  font-size: 22px;
  margin-bottom: 15px;
  color: #333; /* Dark gray color for title */
}

/* Popup Description */
.delete-popup__description {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555; /* Slightly lighter gray for text */
}

/* Button container inside popup */
.delete-popup__buttons {
  display: flex;
  justify-content: space-around;
}

/* Confirm Button */
.delete-popup__button--confirm {
  background-color: #e74c3c; /* Softer red for confirm */
  padding: 12px 24px;
  font-size: 16px; 
  border-radius: 10px;
  color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.delete-popup__button--confirm:hover {
  background-color: #c0392b; /* Darker red on hover */
  transform: scale(1.05);
}

/* Cancel Button */
.delete-popup__button--cancel {
  background-color: #3498db; /* Soft blue for cancel */
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 10px;
  color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.delete-popup__button--cancel:hover {
  background-color: #2980b9; /* Darker blue on hover */
  transform: scale(1.05);
}

.deleteButton{

  border: 2px solid #5f1c1d;
  background-color: white;
  color: #5f1c1d;

}

.deleteButton:hover{

  background-color: #5f1c1d;
  color: white;

}

.pageNumber{

  margin-top: 12px;
  font-size: 21px;
  font-weight: bold;
}

/* Keyframe for fading in overlay */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframe for sliding up popup */
@keyframes slideUp {
  from {
    transform: translateY(30px) scale(0.95);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}
