.css-sprite-heat-tempered-glass
{
background:url('../ColorImages/css-sprite-combined.2.png') 0px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-insulated-glass
{
background:url('../ColorImages/css-sprite-combined.2.png') -150px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-Laminated-Glass
{
background:url('../ColorImages/css-sprite-combined.2.png') -300px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-tempered-glass
{
background:url('../ColorImages/css-sprite-combined.2.png') -450px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-annealed-glass
{
background:url('../ColorImages/css-sprite-combined.2.png') -600px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-flat-glass
{
background:url('../ColorImages/css-sprite-combined.2.png') -750px -0px;
width:150px;height:150px;
display:inline-block;
}

.css-sprite-flat-glass2
{
background:url('../ColorImages/css-sprite-combined.2.png') -900px -0px;
width:150px;height:150px;
display:inline-block;
}
